import Vue from 'vue/dist/vue.esm';
import VueResource from 'vue-resource';
Vue.use(VueResource);

import App from 'views/comteach/ct_seminar_search.vue';

const app = new Vue({
  el: '#search_app',
  render:h=>h(App),
});
